
.ulo-footer {
  color: #FFF;
  background-color: #000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ulo-content-wrapper {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.ulo-footer-follow-us,
.ulo-footer-trademark,
.ulo-footer-signup,
.ulo-footer-signup-button {
  font-family: 'Filson Pro', sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 8px;
  /* Adjust as needed */
}

.ulo-footer-link-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two columns of equal width */
  gap: 20px; /* Adjust the gap to match the design spacing */
  max-width: 600px; /* Adjust max-width to match the design */
  margin: 0 auto; 
}

.ulo-footer-link {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  margin: 10px;
}

.ulo-footer-follow-us {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-left: 4px;
}

.ulo-footer-icon-wrapper {
  display: flex;
  gap: 5px;
}

spacer {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: white;
  margin-top: 30px;
  margin-bottom: 20px;
}

.ulo-footer-links .ulo-footer-link {
  font-size: 12px;
  display: block;
  /* For individual links to stack vertically */
  font-weight: 600;
}

.ulo-footer-trademark {
  font-size: 12px;
  line-height: 20px;
  max-width: 340px;
}

.ulo-footer-signup {
  font-size: 18px;
  line-height: 20px;
  max-width: 340px;
}

.ulo-footer-signup-button {
  width: 144px;
  height: 44px;
  border-radius: 25px;
  border: 1px solid #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background: transparent;
  color: white;
  cursor: pointer;
}