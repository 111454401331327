
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.ulo-App {
  background-color: rgba(253, 251, 249, 1);
  /* font-family: Filson Pro; */
}
.ulo-dev-buttons{
  /* border: red 1px solid; */
  position: absolute;
  width: 60%;
  max-height: 40%;
}
.ulo-dev-buttons button{
  width: 20%;
}

.ulo-content-wrapper {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.ulo-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.ulo-background-gradient {
  background: linear-gradient(to top, rgba(254, 221, 10, 1) 0%, rgba(255, 236, 112, 1) 100%);
}

.ulo-background-gradient-reverse {
  background: linear-gradient(to bottom, rgba(254, 221, 10, 1) 0%, rgba(255, 236, 112, 1) 100%);
}

.ulo-background-img {
  background-image: url('https://s3-alpha-sig.figma.com/img/888b/5eb6/6876e03fb0422636873f3e270d89e8ec?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=hsE0PEw1VcUqgHb6KVk4NlYINURHBrS4gwNONWLQhb3JjEkQ58nxT6yr4TETqmTS83Mi~L0q-LOg1sl0s6XMRZg-~HN1dW5p2E2c1XsH~jNbXJifklGlQ0pEkPlauLzvmz5gepBLV5ihQVK31kCon22GKruqM8oibVSd2e0q8wN2vjvHHIyZtc8A3RroZu0YbxxHLKMp9aA-cwW3JnTHwmofTMF1hsnE70gSG18FxpvmMX594JVLXPaKdUns0pWEQxyN1ssHsQwhUjid3TSfFYmoz-dHSe2XfcHw4eXlyOcSFlXu-qw4nz598XGmc~LcmHyAHu3lAMCJe7nlvAKilA__');
  background-size: cover;
  background-repeat: repeat-y;
}

.ulo-start {
  /* height: 80vh; */
}

.ulo-start-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 60px;
}

.ulo-start-box {
  position: relative;
  width: 306px;
  height: 290px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
}

.ulo-start-photo-window img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.ulo-start-photo-window {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.ulo-photo-0 {
  width: 105px;
  height: 105px;
  left: 83px;
  top: 75px;
}
.ulo-photo-1 {
  width: 85px;
  height: 85px;
  left: -20px;
  top: 150px;
}
.ulo-photo-2 {
  width: 74px;
  height: 74px;
  left: 11px;
  top: 11px;
}
.ulo-photo-3 {
  width: 74px;
  height: 74px;
  left: 161px;
  top: -18px;
}
.ulo-photo-4 {
  width: 67px;
  height: 67px;
  left: 229px;
  top: 69px;
}
.ulo-photo-5 {
  width: 78px;
  height: 78px;
  left: 202px;
  top: 154px;
}
.ulo-photo-6 {
  width: 95px;
  height: 95px;
  left: 100px;
  top: 208px;
}

.ulo-start h1 {
  margin: 0;
  margin-top: 32px;
  font-size: 27px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
}

.ulo-start h2 {
  margin: 5px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;

}

.ulo-start button {
  margin-top: 25px;
  max-width: 264px;
  font-size: 16px;
  font-weight: 600;
}

section {
  padding: 16px;
}

section r {
  color: red;
}

.ulo-section-title {
  color: #272626;
  margin: 9px 0px 12px 3px;
  font-size: 18px;
  font-weight: 500;
  word-wrap: break-word;
}

.ulo-example-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
  height: 158px;
  border-radius: 10px;
  margin: 10px;
  margin-top: 25px;
}

.ulo-example-photo-container {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: -15px;

}

.ulo-example-photo-container div {
  padding: 0px 10px;
}

.ulo-example-text-container {
  text-align: center;
  padding: 15px;
  margin-top: 12%;
}

.ulo-example-text-container h1 {
  color: #191919;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  word-wrap: break-word;
  margin: 5px 0px;
}

.ulo-example-text-container p {
  color: #191919;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
  margin: 0;
}

input {
  padding: 12px 6px 12px 15px;
  margin: 8px 3px 8px 3px;
  display: inline-block;
  border: 1px solid #E2E8F0;
  border-radius: 25px;
  box-sizing: border-box;
  color: #000;
  font-size: 16px;
  outline: none;
  width: 48%;
}

input.ulo-cat-name {
  min-width: 225px;
  width: 47%;
}

.ulo-react-datepicker-wrapper, .ulo-react-datepicker__input-container {
  min-width: 225px;
  width: 47%;
}

input.ulo-date-picker {
  min-width: 225px;
  width: 47%;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

input:focus {
  border-color: #c0caf5;
}

.ulo-subtitle {
  color: #926B08;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
  margin: 3px;
}

.ulo-btn-none {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.ulo-btn {
  padding: 11px 10px;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  word-wrap: break-word;
  width: 100%;
  margin-top: 7px;
  margin-bottom: 7px;
}

.ulo-btn-dark {
  background-color: rgba(37, 37, 37, 1);
  color: rgba(255, 255, 255, 1);
  border: 1px rgba(37, 37, 37, 1) solid;
}

.ulo-btn-light {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
  border: 1px black solid;
}

.ulo-btn-round {
  width: 50px;
  height: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  outline: none;
}

.ulo-add-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: first baseline;
  margin-left: 1px;
}

.ulo-add-button-wrapper p {
  color: #272626;
  font-size: 15px;
  /* THIS font needs to be applied */
  /* font-family: 'SF Pro Text'; */
  font-weight: 500;
  line-height: 16px;
  margin: 10px 10px;
}

.ulo-selection-container {
  margin-top: 9px;
}

.ulo-selection-item {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  border-radius: 10px;
  margin: 0 11px 8px 5px;
  padding: 12px 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  box-sizing: border-box;
}

.ulo-selection-item--add {
  color: #64748B;
  background-color: #FDFBF9;
  border: 1px solid #E2E8F0;
}

.ulo-selection-item--checked {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(74, 68, 63, 1);
  border: 1px solid rgba(74, 68, 63, 1);
}

.ulo-selection-item svg {
  flex-shrink: 0;
  margin-right: 1px;
}

.ulo-photo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.ulo-photo-window-add {
  width: 78px;
  height: 75px;
  border-radius: 5px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.40);
  border: 1px #DCDCDC solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ulo-photo-window-add button {
  background: rgba(219, 219, 219, 0.5)
}

.ulo-photo-window {
  width: 79px;
  height: 78px;
  border-radius: 20px;
  overflow: hidden;
}

.ulo-photo-window img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.ulo-art-style-container {
  display: flex;
  align-items: flex-start;
  overflow-y: scroll;
}

.ulo-art-style-window-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.ulo-art-style-window-container.ulo-selected{
  filter: brightness(50%);
}

.ulo-art-style-checkmark-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.ulo-art-style-checkmark-container svg {
  color: rgba(255, 255, 255, 1);
}

.ulo-style-section.ulo-selected {
  cursor: pointer;
  position: relative;
  
}

.ulo-art-style-window {
  position: relative;
  width: 94px;
  height: 94px;
  border-radius: 20px;
  border: 1px #C0C0C0 solid;
  overflow: hidden;
  background: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ulo-art-style-window-container p {
  margin: 0;
  color: black;
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word
}

.ulo-art-style-window img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ulo-disclaimer-text {
  flex-grow: 1;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
  padding: 10px
}

.ulo-disclaimer-text a {
  color: #2196F3;
  text-decoration: none;
}

.ulo-checkbox-container {
  color: #646464;
  font-size: 14px;
  padding: 5px;
  display: flex;
  align-items: flex-start;
}

.ulo-checkbox {
  margin-right: 10px;
}

.ulo-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.ulo-custom-checkbox {
  width: 18px;
  height: 18px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #D8D8D8;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ulo-custom-checkbox.ulo-checked {
  background-color: #FFD700;
  border: 1px solid #FFD700;
}

.ulo-checkbox-text {
  flex-grow: 1;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word
}

.ulo-checkbox-text a {
  color: #2196F3;
  text-decoration: none;
}

.ulo-checkbox-text a:hover {
  text-decoration: underline;
}

/* NOTE Human approval */

.ulo-human-approval {
  display: flex;
  flex-direction: column;
  max-height: fit-content;
  height: auto;
  background: linear-gradient(to bottom, #fede0a 0%, #ffec70 100%);
  padding: 2rem;
  box-sizing: border-box;
}

.ulo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 150px 1rem 150px;
}

.ulo-header-title {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-left: 36px;
}

.ulo-header-queue {
  display: flex;
  justify-content: flex-end;
}

.ulo-header h1 {
  font-size: 34px;
  font-weight: 500;
  line-height: 41px;
  letter-spacing: 0px;
  margin: 0px;
  padding: 3px;
  text-align: center;
}

.ulo-header p {
  margin: 0px;
  text-align: right;
}

.ulo-desktop-wrapper {
  display: flex;
  flex-grow: 1;
  gap: 1rem;
  margin: 0 150px;
  overflow-y: auto;  /* Add this line */
  height: calc(100vh - 200px);  /* Adjust the value as needed */
}

.ulo-style-wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.ulo-style-wrapper>.ulo-style-section:first-child {
  margin-top: 0px;
}

.ulo-style-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 8px;
  border-radius: 20px;
}

.ulo-style-section p {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}

.ulo-style-section .ulo-photo-window {
  width: 150px;
  height: 150px;
  border-radius: 20px;
  overflow: hidden;
}

.ulo-style-section .ulo-photo-window img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.ulo-style-section .ulo-photo-container.ulo-selected {
  cursor: pointer;
  position: relative;
  filter: brightness(50%);
}

.ulo-checkmark-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.ulo-checkmark-container svg {
  color: rgba(255, 255, 255, 1);
}

.ulo-image-grid {
  padding-left: 30px;
  display: flex;
  gap: 1rem;
}

.ulo-submitted-photos {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;  /* Changed from center */
  align-items: center;
  max-width: 258px;
  width: 100%;
  height: 694px;  /* Changed from max-height */
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}

.ulo-submitted-photos::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: linear-gradient(to bottom, rgba(255, 237, 125, 0) 0%, rgba(255, 237, 125, 0.7) 100%);
  z-index: 100;
}

.ulo-submitted-photos p {
  z-index: 2;
  position: relative;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  background-color: rgba(246, 246, 246, 1);
  max-width: 190px;
  max-height: 30px;
  margin: 25px 0px 20px 0px
}

.ulo-submitted-photos .ulo-photo-container {
  z-index: 2;
  position: relative;
  justify-content: center;

}

.ulo-submitted-photos .ulo-photo-window {
  width: 140px;
  height: 140px;
  border-radius: 20px;
  overflow: hidden;
  margin: 5px 0;
}

.ulo-submitted-photos-wrapper {
  position: relative;
  z-index: 1;
  max-width: 205px;
  width: 100%;
  overflow-y: auto;
}

.ulo-submitted-photos-wrapper {
  scrollbar-width: thin;
  scrollbar-color: rgba(21, 34, 61, 0.5) transparent;
}

.ulo-button-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  gap: 2em;
}

.ulo-button-wrapper .ulo-btn {
  max-width: 400px;
  max-height: 45px;
  margin: 5px;
}

.ulo-button-wrapper .ulo-btn-light {
  background-color: transparent;
}

.ulo-endscreen{
  margin-bottom: 75px;
}

.ulo-endscreen-logo-wrapper{
  position: relative;
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: 40px;
}

.ulo-endscreen-logo-wrapper img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ulo-endscreen h1{
  /* font-family: 'Filson Pro'; */
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center; 
  margin: 10px;
}
.ulo-endscreen h2{
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0;
}

.ulo-human-error {
  color: red;
  font-size: 14px;
  margin-top: -10px;
  margin-left: 3px;
}

.ulo-login {
  height: 100vh;
}

.ulo-login h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
  margin: 50px 10px 10px 10px;
}

.ulo-login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input.ulo-login-input, .ulo-login .ulo-btn {
  width: 50%;
}

.ulo-login .ulo-btn {
  margin-bottom: 100px;
}

.ulo-confirm-rejection {
  padding: 20px;
  box-sizing: border-box;
  position: absolute;
  height: 60%;
  width: 80%;
  top: 20%;
  left: 10%;
  border-radius: 15px;
  background-color: #FFFFFF;
  z-index: 200;
}

.ulo-confirm-rejection-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.ulo-confirm-rejection-content .ulo-button-wrapper {
  width: 100%;
}

.ulo-confirm-rejection-content .ulo-skipped-styles {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.ulo-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 150;
}

.ulo-modal {
  padding: 20px;
  box-sizing: border-box;
  position: absolute;
  height: 60%;
  width: 80%;
  top: 20%;
  left: 10%;
  border-radius: 15px;
  background-color: #FFFFFF;
  z-index: 200;
}

.ulo-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.ulo-modal-content .ulo-button-wrapper {
  width: 100%;
}

.ulo-refresh {
  height: 42.5px;
  width: 42.5px;
}
.ulo-refresh-img {
  height: 100%;
  width: 100%;
}